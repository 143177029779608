import ApiService from "@/core/services/api.service";

export const GetInventory = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`inventory/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const SearchInventory = (search) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.query(`inventory/${search}/search`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetLocationByInventory = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`inventory/${uuid}/locations`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const DeleteInventory = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.delete(`inventory/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const CreateInventory = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post("inventory", params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const UpdateInventory = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.put(`inventory/${uuid}`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const RemoveSuppliers = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`inventory/${uuid}/inventory_supplier`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const ImportProductExcel = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post("product-import", params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const GetQuotationProductListing = (uuid, current_page) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`product/${uuid}/quotes?page=${current_page}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
// Product Module: PO history
// GET http://127.0.0.1:8000/api/v1/product/744b9f8e-8e95-406d-bb39-1fc78fb18957/purchase-orders
export const GetPOMomentHistory = (uuid, page = 1) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`product/${uuid}/purchase-orders?page=${page}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
